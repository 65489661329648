.containerlocaliza{
    height: 50vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .localiza{
   
    width: 500px;
    height: 450px;
    background: #fff;
    }

    @media (max-width: 850px) {
        #imgpesquisa{display: none;}
       
      }
  
    hr{
      background: #fff;
  
    }
    #imglocaliza{
        float: left;
        width: 30%;
    }


    #imgpesquisa{
        width: 700px;
        margin-top: 30px;
    }


   /* Estrutura */
.input-container {
    position: relative;
  }
  
  input {
    border: 0;
    border-bottom: 2px solid #9e9e9e;
    outline: none;
    transition: .2s ease-in-out;
    box-sizing: border-box;
  }
  
  label {
    top: 0;
    left: 0; right: 0;
    color: #616161;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 1rem;
    cursor: text;
    transition: .2s ease-in-out;
    box-sizing: border-box;
  }
  
  input,
  label {
    width: 100%;
    height: 3rem;
    
  }

  /* Interation */
input:valid,
input:focus {
  border-bottom: 2px solid #26a69a;  
}

input:valid + label,
input:focus + label {
  color: #26a69a;
  font-size: .8rem;
  top: -30px;
  pointer-events: none;
}

h1{
    font-size: 50px;
    color: #f85;
}


.btn:link,
.btn:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 40px;
    display: inline-block;
    border-radius: 100px;
    transition: all .2s;
    position: absolute;
}

.btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-white {
    background-color: #26a69a;
    color:black;
    width: 200px;
   
}

.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}

.btn-white::after {
    background-color: #fff;
}

.btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

.btn-animated {
    animation: moveInBottom 5s ease-out;
    animation-fill-mode: backwards;
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

